body{
    background-color: #d0d0ce;
    font-family: frutiger, Arial, sans-serif;
    color: #434448;
   background-repeat: no-repeat;
   margin: 0;
}

@font-face{font-family:'frutiger';font-weight: 300; src:url("custom/fonts/frutiger-light.woff") format("woff")}
@font-face{font-family:'frutiger';font-weight: 400; src:url("custom/fonts/frutiger-roman.woff") format("woff")}
@font-face{font-family:'frutiger';font-weight: bold; src:url("custom/fonts/frutiger-bold.woff") format("woff")}
@font-face{font-family:'Optum-Micro-interaction-16';font-weight: bold; src:url("custom/fonts/Optum-Micro-interaction-16.woff2") format("woff")}
