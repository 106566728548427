body {
  color: #434448;
  background-color: #d0d0ce;
  background-repeat: no-repeat;
  margin: 0;
  font-family: frutiger, Arial, sans-serif;
}

@font-face {
  font-family: frutiger;
  font-weight: 300;
  src: url("frutiger-light.d89235a1.woff") format("woff");
}

@font-face {
  font-family: frutiger;
  font-weight: 400;
  src: url("frutiger-roman.676d8e73.woff") format("woff");
}

@font-face {
  font-family: frutiger;
  font-weight: bold;
  src: url("frutiger-bold.ddbcee20.woff") format("woff");
}

@font-face {
  font-family: Optum-Micro-interaction-16;
  font-weight: bold;
  src: url("Optum-Micro-interaction-16.4f691dba.woff2") format("woff");
}
/*# sourceMappingURL=index.87e34283.css.map */
